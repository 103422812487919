import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import TopBanner from './TopBanner';
import { Form, Formik } from 'formik';
import FormikVitlDateField from '../../components/forms/FormikVitlDateField';
import moment from 'moment';
import { Box, Paper, Slide, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getLeadById, getPublicLead, scheduleAppointment } from '../../services/leads.service';
import AvailableTimeSlotsField from './AvailableTimeSlotsField';
import { getCurrentAppointment } from 'shared/src/leads';
import { Lead } from 'database';
import { time24to12 } from 'react-helpers';
import FormikDebugger from '../../components/forms/FormikDebugger';
import { SubmitButton } from 'react-helpers/src/components/forms';

//http://localhost:3006/gen/2/69/2808

const ScheduleAppointment = () => {
    const { leadId, isNewLead } = useParams();
    const {
        data: lead,
        isFetched,
        isLoading: isLoadingTimeslots,
    } = useQuery(['lead', leadId], () => getPublicLead(leadId));
    const currentAppointment = useMemo(() => {
        if (!lead) return null;
        return getCurrentAppointment(lead as Lead);
    }, [lead]);

    const navigate = useNavigate();
    const { mutate: scheduleAppointmentMutation, isLoading: isSchedulingAppointment } = useMutation(
        scheduleAppointment,
        {
            onSuccess: (data) => {
                navigate(`/gen/schedule-success/${data.appointmentId}`);
            },
        },
    );

    if (!isFetched) return <Box sx={{ textAlign: 'center', p: 2 }}>Loading...</Box>;
    return (
        <Box p={2}>
            <TopBanner />
            {currentAppointment && !currentAppointment.dispositionId ? (
                <>
                    <Box my={2}>
                        You already have an appointment for{' '}
                        {moment.utc(currentAppointment.sitDate).format('dddd, MMMM Do, YYYY')} at{' '}
                        {time24to12(currentAppointment.startTime)}. We will talk to you then!
                    </Box>
                </>
            ) : (
                <>
                    <Box my={4}>
                        <Paper>
                            <Box p={2}>
                                <Typography>Scheduling Consultation For:</Typography>
                                <Box mt={1}>
                                    <Typography>
                                        {lead?.firstName} {lead?.lastName}
                                    </Typography>
                                    <Typography>{lead.streetAddress1}</Typography>
                                    <Typography>
                                        {lead.city}, {lead.state} {lead.zip}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                    <Formik
                        initialValues={{
                            appointmentDate: '',
                            setterTeamId: lead?.setterTeamId,
                            startTime: '',
                            endTime: '',
                        }}
                        onSubmit={(values) => {
                            scheduleAppointmentMutation({
                                id: leadId,
                                body: {
                                    appointmentDate: values.appointmentDate,
                                    startTime: values.startTime,
                                },
                            });
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <FormikDebugger />
                                <FormikVitlDateField
                                    name="appointmentDate"
                                    label="Select an Consultation Date"
                                    textFieldProps={{}}
                                    shouldDisableDate={(date) => {
                                        return moment().add(1, 'day').startOf('day').isAfter(date);
                                    }}
                                />
                                <AvailableTimeSlotsField />
                                <Slide
                                    direction="up"
                                    in={!!values.appointmentDate && !!values.startTime && !isLoadingTimeslots}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <Box position="sticky" bottom={0} width="100%" p={2}>
                                        <SubmitButton isLoading={isSchedulingAppointment}>
                                            Schedule for {moment.utc(values.appointmentDate).format('ddd, MMM D')} at{' '}
                                            {time24to12(values.startTime)}
                                        </SubmitButton>
                                    </Box>
                                </Slide>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Box>
    );
};

export default ScheduleAppointment;
