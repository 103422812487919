import React, { useEffect } from 'react';
import { create } from 'zustand';

type DialogFunction = () => void;
interface ConfirmDialogStore {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    confirmText: string;
    setConfirmText: (confirmText: string) => void;
    cancelText: string;
    setCancelText: (cancelText: string) => void;
    onConfirm: DialogFunction;
    setOnConfirm: (onConfirm: DialogFunction) => void;
    onCancel: DialogFunction;
    setOnCancel: (onCancel: DialogFunction) => void;
    body: React.ReactNode;
    setBody: (body: React.ReactNode) => void;
    confirm: (
        body: React.ReactNode,
        options?: { confirmText?: string; cancelText?: string; onConfirm?: () => void; onCancel?: () => void },
    ) => void;
}

const useConfirmDialog = create<ConfirmDialogStore>((set) => ({
    isOpen: false,
    setIsOpen: (isOpen: boolean) => set({ isOpen }),
    confirmText: 'OK',
    setConfirmText: (confirmText: string) => set({ confirmText }),
    cancelText: 'Cancel',
    setCancelText: (cancelText: string) => set({ cancelText }),
    onConfirm: () => {},
    setOnConfirm: (onConfirm: DialogFunction) => set({ onConfirm }),
    onCancel: () => {},
    setOnCancel: (onCancel: DialogFunction) => set({ onCancel }),
    body: '',
    setBody: (body: React.ReactNode) => set({ body }),
    confirm: (
        body: React.ReactNode,
        options?: { confirmText?: string; cancelText?: string; onConfirm?: () => void; onCancel?: () => void },
    ) => {
        set({ body });
        set({ isOpen: true });
        if (options?.onConfirm) set({ onConfirm: options?.onConfirm });
        if (options?.onCancel) set({ onCancel: options?.onCancel });
        if (options?.confirmText) set({ confirmText: options?.confirmText });
        if (options?.cancelText) set({ cancelText: options?.cancelText });
    },
}));

export default useConfirmDialog;
