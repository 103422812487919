import { Appointment } from 'database';
import {
    CheckInDto,
    ExpandedAppointment,
    GetAppointmentsQueryParams,
    PaginatedQueryResponse,
    PublicAppointment,
} from 'shared';
import { http } from '../http/http';

const ENDPOINT = 'appointments';

export const getAppointments = async (
    query: GetAppointmentsQueryParams,
): Promise<PaginatedQueryResponse<ExpandedAppointment>> => {
    const { data: response } = await http.get<PaginatedQueryResponse<ExpandedAppointment>>(`${ENDPOINT}`, {
        params: query,
    });
    return response;
};

export const getPublicAppointment = async (id: string): Promise<PublicAppointment> => {
    const { data: response } = await http.get<PublicAppointment>(`${ENDPOINT}/${id}/public`);
    return response;
};

export const getCheckIns = async (): Promise<Appointment[]> => {
    const { data: response } = await http.get<Appointment[]>(`${ENDPOINT}/checkIns`);
    return response;
};

export const postCheckIn = async (dto: CheckInDto): Promise<void> => {
    const { data: response } = await http.post<void>(`${ENDPOINT}/checkIns`, dto);
    return response;
};
