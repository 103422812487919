import Yup from '../../yup';

export const CreateLeadSchema = Yup.object({
    name: Yup.string().required('Name is required.'),
    email: Yup.string().matches(
        /^(|[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+)$/,
        'Email must be empty or a valid email address.',
    ),
    phone: Yup.string().matches(/^(\d{10}|)$/, 'Phone must be empty or a valid phone number.'),
    address: Yup.string().required('Address is required.'),
    streetAddress1: Yup.string().required('Street Address 1 is required.'),
    streetAddress2: Yup.string().nullable().castToEmptyStringIfNull(),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().required('Zip is required.'),
    setterTeamId: Yup.string().required('Setter Team is required.'),
    setterId: Yup.string().required('Setter is required.'),
    unlicensedSetterId: Yup.string().nullable(),
    hasAppointment: Yup.boolean().required('Has Appointment is required.'),
    appointmentDate: Yup.date().when('hasAppointment', {
        is: true,
        then: Yup.date().required('Appointment Date is required.'),
        otherwise: Yup.date(),
    }),
    closerTeamId: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .when('hasAppointment', {
            is: true,
            then: Yup.string().required('Closer Team ID is required if appointment is scheduled.'),
            otherwise: Yup.string().nullable().castToEmptyStringIfNull(),
        }),
    startTime: Yup.string().when('hasAppointment', {
        is: true,
        then: Yup.string().required('Start Time is required if appointment is scheduled.'),
        otherwise: Yup.string(),
    }),
    endTime: Yup.string().when('hasAppointment', {
        is: true,
        then: Yup.string().required('End Time is required if appointment is scheduled.'),
        otherwise: Yup.string(),
    }),
    notes: Yup.string().nullable().castToEmptyStringIfNull(),
    isRetail: Yup.boolean().required('Is Retail is required.'),
    storeId: Yup.string()
        .nullable()
        .castToEmptyStringIfNull()
        .when('isRetail', {
            is: true,
            then: Yup.string().required('Store ID is required if is retail.'),
            otherwise: Yup.string().nullable().castToEmptyStringIfNull(),
        }),
    freedomLeadId: Yup.string().nullable(),
});

// wish we could use this with tsoa https://github.com/lukeautry/tsoa/issues/1256
export type CreateLeadSchemaDto = Yup.InferType<typeof CreateLeadSchema>;

// hard interface version of the schema naming cause CreateLeadDto is already taken
export interface ICreateLead {
    name: string;
    email: string;
    phone: string;
    streetAddress1: string;
    streetAddress2: string | null | undefined;
    city: string;
    state: string;
    zip: string;
    address: string;
    setterTeamId: string;
    setterId: string;
    unlicensedSetterId: string | null | undefined;
    hasAppointment: boolean;
    appointmentDate: Date | null | undefined;
    closerTeamId: string | null | undefined;
    startTime: string | null | undefined;
    endTime: string | null | undefined;
    notes: string | null | undefined;
    isRetail: boolean;
    storeId: string | null | undefined;
    freedomLeadId: string | null | undefined;
}
