import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router';
import { getPublicAppointment } from '../../services/appointments.service';
import { Box, Paper, Typography } from '@mui/material';
import { time24to12 } from 'react-helpers';
import moment from 'moment';

const ScheduleSuccess = () => {
    const { appointmentId } = useParams();
    const { data: appointment } = useQuery({
        queryKey: ['appointment', appointmentId],
        queryFn: () => getPublicAppointment(appointmentId),
    });

    if (!appointment) return null;
    return (
        <Box p={2}>
            <Paper>
                <Box p={2} textAlign="center">
                    <Typography variant="h4">Appointment Scheduled!</Typography>
                    <br />
                    <br />
                    <Typography variant="h5">
                        We look forward to seeing you on{' '}
                        <u>
                            {moment(appointment?.sitDate).format('ddd, MMM Do')} at {time24to12(appointment?.startTime)}
                        </u>
                        !
                    </Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default ScheduleSuccess;
