import {
    ArrowDownward,
    ArrowUpward,
    Close,
    FilterAlt,
    Search,
    SentimentDissatisfiedOutlined,
    Sort,
} from '@mui/icons-material';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
    AppBar,
    Autocomplete,
    AutocompleteValue,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Chip,
    CircularProgress,
    ClickAwayListener,
    Dialog,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grow,
    IconButton,
    InputAdornment,
    List,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Skeleton,
    Slide,
    SlideProps,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Visible } from 'react-grid-system';
import { useGridSystem } from '../../hooks/useGridSystem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useDebounce from '../../hooks/useDebounce';
import { BOTTOM_NAV_Z_INDEX } from '../../styles/styles';
import { DatePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Loader from '../Loader';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DatatableVirtualizedAutocomplete from './DatatableVirtualizedAutocomplete';

export const DATATABLE_ALL_ROWS = 99999999;

const Transition = React.forwardRef(function Transition(props: SlideProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface DatatableColumn<T> {
    title: string;
    field?: keyof T;
    render?: (obj: T) => string | ReactNode;
    isSortable?: boolean;
}

export interface PopperOptions<T> {
    icon: ReactNode;
    label: string;
    onClick?: (obj: T) => void;
    link?: string;
}

export interface SortByOption {
    label: string;
    field: string;
}

interface DatatableProps<T, Filters> {
    data?: T[];
    count?: number;
    isFetching: boolean;
    columns: DatatableColumn<T>[];
    page: number;
    pageSize: number;
    pageOptions: number[];
    searchTerm: string;
    setSearchTerm: (search: string) => void;
    setPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    filterOptions?: (DatatableFilter<Filters> | null)[];
    filters: Filters;
    setFilters: (filters: Filters) => void;
    sortBy?: string;
    setSortBy?: (sortBy: any) => void;
    orderBy?: 'asc' | 'desc';
    setOrderBy?: (orderBy: 'asc' | 'desc') => void;
    filterModalLabel?: string;
    sortModalLabel?: string;
    navigateToOnRowClick?: (obj: T) => string;
    openNewTabOnRowClick?: boolean;
    getPopperOptions?: (row: T) => PopperOptions<T>[];
    mobileComponent: (row: T) => ReactNode;
    rightNode?: ReactNode;
    onBottomMobileComponentReached?: () => void;
    hasNextPage?: boolean;
    isLoadingFilters?: boolean;
    filterButton?: ReactNode;
    hideActions?: boolean;
}

export interface QueryOption {
    text: string;
    value: string;
}

export interface DatatableFilter<Filters> {
    label: string;
    queryParam: keyof Filters;
    options: QueryOption[];
    type:
        | 'radio'
        | 'checkbox'
        | 'autocomplete-multiple'
        | 'autocomplete'
        | 'daterange'
        | 'text'
        | 'startUtcDate'
        | 'endUtcDate'
        | 'startDate'
        | 'endDate';
}

export const Datatable = <T extends { id: string | number }, Filters extends {}>({
    data,
    count,
    page,
    pageSize,
    isFetching,
    pageOptions,
    setPage,
    setPageSize,
    columns,
    searchTerm,
    setSearchTerm,
    filterOptions,
    filters,
    setFilters,
    filterModalLabel = 'Filters',
    navigateToOnRowClick,
    openNewTabOnRowClick,
    sortBy,
    setSortBy,
    orderBy,
    setOrderBy,
    getPopperOptions,
    mobileComponent,
    rightNode,
    onBottomMobileComponentReached,
    hasNextPage,
    isLoadingFilters,
    filterButton,
    hideActions,
}: DatatableProps<T, Filters>) => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage + 1);
    };
    const { md } = useGridSystem();
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(Number(event.target.value));
        setPage(1);
    };
    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if (inView && entry && entry.isIntersecting) {
            onBottomMobileComponentReached && onBottomMobileComponentReached();
        }
    }, [inView, entry]);
    const [search, setSearch] = useState(searchTerm);
    const debouncedSearchTerm = useDebounce(search, 333);
    useEffect(() => {
        setSearch(searchTerm);
    }, [searchTerm]);
    useEffect(() => {
        setSearchTerm(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Container>
                <Row align="center">
                    <Col md={8}>
                        <Box mb={2}>
                            <TextField
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (event.target.value === '') {
                                        setSearchTerm('');
                                    }
                                    setSearch(event.target.value);
                                }}
                                onBlur={(_: never) => {
                                    setSearchTerm(search);
                                }}
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={search}
                                size="small"
                                placeholder="Search"
                                onKeyUp={(event: React.KeyboardEvent) => {
                                    if (event.key === 'Enter') {
                                        setSearchTerm(search);
                                    }
                                    setPage(1);
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <>
                                            <IconButton
                                                size="small"
                                                onClick={(event: React.MouseEvent) => {
                                                    setSearchTerm('');
                                                    setSearch('');
                                                    setPage(1);
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </>
                                    ),
                                }}
                            />
                        </Box>
                    </Col>
                    {filterOptions && (
                        <Col md={rightNode ? 2 : 4} xs={rightNode ? 6 : 12}>
                            <Box mb={2}>
                                {filterButton ? (
                                    filterButton
                                ) : (
                                    <Button
                                        variant={Object.keys(filters).length > 0 ? 'contained' : 'outlined'}
                                        onClick={() => {
                                            setIsFilterModalOpen(true);
                                            searchParams.set('filters', 'open');
                                            setSearchParams(searchParams);
                                        }}
                                        fullWidth
                                        startIcon={<FilterAlt />}
                                    >
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            Filters
                                            {Object.keys(filters).length > 0 && (
                                                <Typography sx={{ ml: 2 }}>
                                                    <b>{Object.keys(filters).length}</b>
                                                </Typography>
                                            )}
                                        </Box>
                                    </Button>
                                )}
                                <Dialog
                                    TransitionComponent={Transition}
                                    fullWidth
                                    fullScreen={md}
                                    open={isFilterModalOpen || searchParams.get('filters') === 'open'}
                                    onClose={() => {
                                        searchParams.delete('filters');
                                        setSearchParams(searchParams);
                                        setIsFilterModalOpen(false);
                                    }}
                                    title={filterModalLabel}
                                    style={{
                                        zIndex: BOTTOM_NAV_Z_INDEX + 1,
                                    }}
                                    PaperProps={{
                                        sx: {
                                            background: 'linear-gradient(to bottom, #3B3E40, #121212)',
                                            borderRadius: 4,
                                        },
                                    }}
                                >
                                    <AppBar style={{ position: 'sticky' }}>
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box display="flex" alignItems="center">
                                                <Box paddingLeft={2}>
                                                    <IconButton
                                                        edge="start"
                                                        color="inherit"
                                                        onClick={() => {
                                                            searchParams.delete('filters');
                                                            setSearchParams(searchParams);
                                                            setIsFilterModalOpen(false);
                                                        }}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                </Box>
                                                <Box py={2}>
                                                    <Typography>{filterModalLabel}</Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Chip
                                                    sx={{ mr: 2 }}
                                                    label={Object.keys(filters).length + ' filters applied'}
                                                    onDelete={() => setFilters({} as Filters)}
                                                />
                                            </Box>
                                        </Box>
                                    </AppBar>
                                    <DatatableFilters
                                        filters={filters}
                                        setFilters={setFilters}
                                        filterOptions={filterOptions}
                                        setPage={setPage}
                                        isLoadingFilters={isLoadingFilters}
                                    />
                                </Dialog>
                            </Box>
                        </Col>
                    )}
                    {rightNode && (
                        <Col md={2} xs={6}>
                            <Box mb={2}>{rightNode}</Box>
                        </Col>
                    )}
                </Row>
                <Visible xxl xl lg md>
                    <>
                        <Row>
                            <Col lg={12}>
                                <Box>
                                    {pageSize === DATATABLE_ALL_ROWS ? (
                                        <VirtualizedDatatableBody
                                            setSortBy={setSortBy}
                                            sortBy={sortBy}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                            data={data}
                                            columns={columns}
                                            openNewTabOnRowClick={openNewTabOnRowClick}
                                            navigateToOnRowClick={navigateToOnRowClick}
                                            getPopperOptions={getPopperOptions}
                                            pageSize={pageSize}
                                            isFetching={isFetching}
                                        />
                                    ) : (
                                        <DatatableBody
                                            setSortBy={setSortBy}
                                            sortBy={sortBy}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                            data={data}
                                            columns={columns}
                                            openNewTabOnRowClick={openNewTabOnRowClick}
                                            navigateToOnRowClick={navigateToOnRowClick}
                                            getPopperOptions={getPopperOptions}
                                            pageSize={pageSize}
                                            isFetching={isFetching}
                                            hideActions={hideActions}
                                        />
                                    )}
                                </Box>
                            </Col>
                        </Row>
                    </>
                </Visible>
                <Visible sm xs>
                    {isFetching ? (
                        <Box textAlign="center" mt={3}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ mx: -3 }}>
                            <List
                                sx={{
                                    minHeight: '50vh',
                                }}
                            >
                                {data?.map((row: T) => <Fragment key={row.id}>{mobileComponent(row)}</Fragment>)}
                            </List>

                            {data && data.length > 0 ? (
                                <Box
                                    width={1}
                                    ref={ref}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    p={2}
                                >
                                    {inView &&
                                        (hasNextPage ? (
                                            <CircularProgress />
                                        ) : (
                                            <Box textAlign="center" mt={3}>
                                                End of results.
                                            </Box>
                                        ))}
                                </Box>
                            ) : (
                                <Box textAlign={'center'} mt={3}>
                                    <SentimentDissatisfiedOutlined />
                                    <Typography>No data found.</Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Visible>
                <Visible xxl xl lg md>
                    <Row>
                        <Col lg={12}>
                            <TablePagination
                                component="div"
                                count={count || 0}
                                labelRowsPerPage={'Rows:'}
                                page={page - 1}
                                rowsPerPageOptions={pageOptions.map((num) =>
                                    num === DATATABLE_ALL_ROWS ? { label: 'All', value: DATATABLE_ALL_ROWS } : num,
                                )}
                                onPageChange={handleChangePage}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Col>
                    </Row>
                </Visible>
            </Container>
        </LocalizationProvider>
    );
};

const VirtualizedDatatableBody = <T extends { id: string | number }>({
    data,
    columns,
    pageSize,
    isFetching,
    navigateToOnRowClick,
    openNewTabOnRowClick,
    getPopperOptions,
    sortBy,
    setSortBy,
    orderBy,
    setOrderBy,
}: {
    data?: T[];
    columns: DatatableColumn<T>[];
    pageSize: number;
    isFetching: boolean;
    navigateToOnRowClick?: (obj: T) => string;
    openNewTabOnRowClick?: boolean;
    getPopperOptions?: (row: T) => PopperOptions<T>[];
    sortBy?: string;
    setSortBy?: (field: string) => void;
    orderBy?: 'asc' | 'desc';
    setOrderBy?: (order: 'asc' | 'desc') => void;
}) => {
    const virtualizer = useVirtualizer({
        count: data?.length || 0,
        getScrollElement: () => document.getElementById('virtualizer-scroll'),
        estimateSize: () => 35,
    });

    const virtualItems = virtualizer.getVirtualItems();
    const totalSize = virtualizer.getTotalSize();
    const paddingTop = virtualItems.length > 0 ? virtualItems[0].start : 0;
    const paddingBottom = virtualItems.length > 0 ? totalSize - virtualItems[virtualItems.length - 1].end : 0;

    return (
        <div style={{ height: '70vh', overflow: 'auto' }} id="virtualizer-scroll">
            <Box sx={{ overflowX: 'auto' }}>
                <Table size="small" sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell
                                    onClick={() => {
                                        if (!col.field || !setSortBy || !setOrderBy) return;
                                        setSortBy(col.field.toString());
                                        if (col.field === sortBy) {
                                            setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
                                        } else {
                                            setOrderBy('asc');
                                        }
                                    }}
                                    key={col.title}
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <b style={{ cursor: col.isSortable === true ? 'pointer' : 'default' }}>
                                            {col.title}
                                        </b>
                                        {col.field && col.field === sortBy && (
                                            <Box ml={1}>
                                                {orderBy === 'asc' ? (
                                                    <ArrowUpward sx={{ fontSize: 16, color: '#888' }} />
                                                ) : (
                                                    <ArrowDownward sx={{ fontSize: 16, color: '#888' }} />
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </TableCell>
                            ))}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {isFetching ? (
                        <TableBody>
                            {[...Array(pageSize)].map((_, idx) => (
                                <TableRow key={'skele' + idx}>
                                    <TableCell key={`skele${idx}`} colSpan={columns.length + 1}>
                                        <Skeleton height={32} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            {data && (
                                <>
                                    <tr style={{ height: `${paddingTop}px` }} />
                                    {virtualItems.map((virtualRow) => {
                                        const row = data[virtualRow.index];
                                        return (
                                            <DatatableRow
                                                key={`${row.id}dtrow`}
                                                row={row}
                                                columns={columns}
                                                index={virtualRow.index}
                                                openNewTabOnRowClick={openNewTabOnRowClick}
                                                navigateToOnRowClick={navigateToOnRowClick}
                                                getPopperOptions={getPopperOptions}
                                            />
                                        );
                                    })}
                                    <tr style={{ height: `${paddingBottom}px` }} />
                                </>
                            )}
                        </TableBody>
                    )}
                </Table>
            </Box>
        </div>
    );
};

const DatatableBody = <T extends { id: string | number }>({
    data,
    columns,
    pageSize,
    isFetching,
    navigateToOnRowClick,
    openNewTabOnRowClick,
    getPopperOptions,
    sortBy,
    setSortBy,
    orderBy,
    setOrderBy,
    hideActions,
}: {
    data?: T[];
    columns: DatatableColumn<T>[];
    pageSize: number;
    isFetching: boolean;
    navigateToOnRowClick?: (obj: T) => string;
    openNewTabOnRowClick?: boolean;
    getPopperOptions?: (row: T) => PopperOptions<T>[];
    sortBy?: string;
    setSortBy?: (field: string) => void;
    orderBy?: 'asc' | 'desc';
    setOrderBy?: (order: 'asc' | 'desc') => void;
    hideActions?: boolean;
}) => {
    return (
        <Box sx={{ overflowX: 'auto' }}>
            <Table size="small" sx={{ minWidth: 800 }}>
                <TableHead>
                    <TableRow>
                        {columns.map((col) => (
                            <TableCell
                                key={col.title}
                                sx={{ whiteSpace: 'nowrap' }}
                                onClick={() => {
                                    if (!col.field || !setSortBy || !setOrderBy) return;
                                    setSortBy(col.field.toString());
                                    if (col.field === sortBy) {
                                        setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
                                    } else {
                                        setOrderBy('asc');
                                    }
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <b style={{ cursor: col.isSortable === true ? 'pointer' : 'default' }}>
                                        {col.title}
                                    </b>
                                    {col.field && col.field === sortBy && (
                                        <Box ml={1}>
                                            {orderBy === 'asc' ? (
                                                <ArrowUpward sx={{ fontSize: 16, color: '#888' }} />
                                            ) : (
                                                <ArrowDownward sx={{ fontSize: 16, color: '#888' }} />
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </TableCell>
                        ))}
                        {!hideActions && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                {isFetching ? (
                    <TableBody>
                        {[...Array(pageSize)].map((_, idx) => (
                            <TableRow key={'skele' + idx}>
                                <TableCell key={`skele${idx}`} colSpan={columns.length + 1}>
                                    <Skeleton height={32} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                    <TableBody>
                        {data &&
                            data.map((row, index) => (
                                <DatatableRow
                                    key={`${index}dtrow`}
                                    row={row}
                                    columns={columns}
                                    index={index}
                                    openNewTabOnRowClick={openNewTabOnRowClick}
                                    navigateToOnRowClick={navigateToOnRowClick}
                                    getPopperOptions={getPopperOptions}
                                    hideActions={hideActions}
                                />
                            ))}
                    </TableBody>
                )}
            </Table>
        </Box>
    );
};

const DatatableFilters = <Filters extends {}>({
    filters,
    setFilters,
    filterOptions,
    setPage,
    isLoadingFilters,
}: {
    filters: any;
    setFilters: (filters: any) => void;
    filterOptions: (DatatableFilter<Filters> | null)[];
    setPage: (page: number) => void;
    isLoadingFilters?: boolean;
}) => {
    if (isLoadingFilters)
        return (
            <Box p={2}>
                {Array.from({ length: 5 }).map((_, idx) => (
                    <Skeleton height={64} key={'skele' + idx} />
                ))}
            </Box>
        );
    const getFilterComponent = (f: DatatableFilter<Filters>) => {
        if (f.type === 'radio') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{f.label.toUpperCase()}</FormLabel>
                        <RadioGroup
                            name={f.label}
                            value={filters[f.queryParam]}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                const result = {
                                    ...filters,
                                    [f.queryParam]: value,
                                };
                                if (!result.queryParam) delete result.queryParam;
                                setFilters(result);
                                setPage(1);
                            }}
                        >
                            {f.options.map((qValue: QueryOption) => (
                                <FormControlLabel
                                    key={qValue.value}
                                    value={qValue.value}
                                    control={<Radio />}
                                    label={qValue.text}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            );
        }

        if (f.type === 'startUtcDate') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <DesktopDatePicker
                        label={f.label.toUpperCase()}
                        closeOnSelect={true}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                size: 'small',
                            },
                            popper: {
                                sx: {
                                    zIndex: BOTTOM_NAV_Z_INDEX + 2,
                                },
                            },
                        }}
                        defaultValue={filters[f.queryParam] && moment.utc(filters[f.queryParam])}
                        onChange={(mDate: Moment | null) => {
                            const result = {
                                ...filters,
                                [f.queryParam]: moment.utc(mDate).startOf('day').toISOString(),
                            };
                            if (!mDate || !mDate.isValid()) {
                                delete result[f.queryParam];
                            }
                            setFilters(result);
                        }}
                    />
                </Box>
            );
        }

        if (f.type === 'endUtcDate') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <DesktopDatePicker
                        label={f.label.toUpperCase()}
                        closeOnSelect={true}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                size: 'small',
                            },
                            popper: {
                                sx: {
                                    zIndex: BOTTOM_NAV_Z_INDEX + 2,
                                },
                            },
                        }}
                        defaultValue={filters[f.queryParam] && moment.utc(filters[f.queryParam])}
                        onChange={(mDate: Moment | null) => {
                            const result = {
                                ...filters,
                                [f.queryParam]: moment.utc(mDate).endOf('day').toISOString(),
                            };
                            if (!mDate || !mDate.isValid()) {
                                delete result[f.queryParam];
                            }
                            setFilters(result);
                        }}
                    />
                </Box>
            );
        }

        if (f.type === 'startDate') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <DesktopDatePicker
                        label={f.label.toUpperCase()}
                        closeOnSelect={true}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                size: 'small',
                            },
                            popper: {
                                sx: {
                                    zIndex: BOTTOM_NAV_Z_INDEX + 2,
                                },
                            },
                        }}
                        views={['day']}
                        defaultValue={filters[f.queryParam] && moment(filters[f.queryParam])}
                        onChange={(mDate: Moment | null) => {
                            const result = {
                                ...filters,
                                [f.queryParam]: moment(mDate).startOf('day').toISOString(),
                            };
                            if (!mDate || !mDate.isValid()) {
                                delete result[f.queryParam];
                            }
                            setFilters(result);
                        }}
                    />
                </Box>
            );
        }

        if (f.type === 'endDate') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <DesktopDatePicker
                        label={f.label.toUpperCase()}
                        closeOnSelect={true}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                size: 'small',
                            },
                            popper: {
                                sx: {
                                    zIndex: BOTTOM_NAV_Z_INDEX + 2,
                                },
                            },
                        }}
                        views={['day']}
                        defaultValue={filters[f.queryParam] && moment(filters[f.queryParam])}
                        onChange={(mDate: Moment | null) => {
                            const result = {
                                ...filters,
                                [f.queryParam]: moment(mDate).endOf('day').toISOString(),
                            };
                            if (!mDate || !mDate.isValid()) {
                                delete result[f.queryParam];
                            }
                            setFilters(result);
                        }}
                    />
                </Box>
            );
        }

        if (f.type === 'autocomplete-multiple') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <DatatableVirtualizedAutocomplete
                        multiple
                        options={f.options}
                        disableCloseOnSelect
                        getOptionLabel={(option) => (option as QueryOption).text}
                        value={
                            (f?.options?.filter((o) => filters[f.queryParam]?.includes(o.value)) ||
                                []) satisfies AutocompleteValue<QueryOption[], false, false, false>
                        }
                        filterSelectedOptions
                        onChange={(event: React.ChangeEvent<{}>, value: any, reason: any) => {
                            const result = {
                                ...filters,
                                [f.queryParam]: value.map((v) => v.value),
                            };
                            if (!value.map((v) => v.value) || value.length === 0) delete result[f.queryParam];
                            setFilters(result);
                        }}
                        ChipProps={{
                            color: 'success',
                        }}
                        size="small"
                        label={f.label.toUpperCase()}
                    />
                </Box>
            );
        }

        if (f.type === 'autocomplete') {
            return (
                <Box key={f.queryParam as string} my={2}>
                    <DatatableVirtualizedAutocomplete
                        options={f.options}
                        disableCloseOnSelect
                        getOptionLabel={(option) => (option as QueryOption).text}
                        value={f.options.find((o) => o.value == filters[f.queryParam]) as QueryOption}
                        filterSelectedOptions
                        onChange={(event: React.ChangeEvent<{}>, value: any, reason: any) => {
                            const result = {
                                ...filters,
                                [f.queryParam]: value?.value || '',
                            };
                            if (!result[f.queryParam]) delete result[f.queryParam];
                            setFilters(result);
                        }}
                        ChipProps={{
                            color: 'success',
                        }}
                        label={f.label.toUpperCase()}
                    />
                </Box>
            );
        }

        if (f.type === 'text') {
            return (
                <Box my={2}>
                    <Box mt={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={f.label.toUpperCase()}
                            type="text"
                            placeholder=""
                            value={filters[f.queryParam] || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFilters({
                                    ...filters,
                                    [f.queryParam]: event.target.value,
                                });
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            let copyOfQuery = {
                                                ...filters,
                                            };
                                            delete copyOfQuery[f.queryParam];
                                            setFilters(copyOfQuery);
                                        }}
                                    >
                                        <Close />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                </Box>
            );
        }
    };
    return <Box px={2}>{filterOptions.filter((f) => !!f).map((filter) => getFilterComponent(filter!))}</Box>;
};

const DatatableRow = <T extends { id: string | number }>({
    row,
    columns,
    index,
    navigateToOnRowClick,
    openNewTabOnRowClick,
    getPopperOptions,
    hideActions,
}: {
    row: T;
    columns: DatatableColumn<T>[];
    index: any;
    navigateToOnRowClick?: (row: T) => string;
    openNewTabOnRowClick?: boolean;
    getPopperOptions?: (row: T) => PopperOptions<T>[];
    hideActions?: boolean;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handlePopperClose = (event: React.MouseEvent<any>) => {
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Fragment>
            <TableRow
                sx={{
                    ':hover': {
                        backgroundColor: 'transparent',
                        background: 'linear-gradient(180deg,rgb(115, 170, 20) 0%, #50760d 100%)',
                    },
                    cursor: 'pointer',
                }}
            >
                {columns.map((col) => (
                    <TableCell
                        onClick={() => {
                            if (navigateToOnRowClick) {
                                openNewTabOnRowClick
                                    ? window.open(navigateToOnRowClick(row))
                                    : navigate(navigateToOnRowClick(row));
                            }
                        }}
                        key={`${row.id}${col.title}`}
                    >
                        {renderField(row, col, index)}
                    </TableCell>
                ))}
                {!hideActions && (
                    <TableCell>
                        <IconButton size="small" onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            {getPopperOptions && (
                <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement={'bottom-start'}
                    transition
                    style={{ zIndex: 99999999 }}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={(event: any) => handlePopperClose(event)}>
                                    <MenuList>
                                        {getPopperOptions(row).length > 0 ? (
                                            getPopperOptions(row).map((option) =>
                                                option.link ? (
                                                    <Link
                                                        to={option.link}
                                                        key={option.label}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <MenuItem>
                                                            <ListItemIcon>{option.icon}</ListItemIcon>
                                                            <ListItemText
                                                                primary={option.label}
                                                                sx={{
                                                                    color: theme.palette.text.primary,
                                                                }}
                                                            />
                                                        </MenuItem>
                                                    </Link>
                                                ) : (
                                                    <MenuItem
                                                        key={option.label}
                                                        onClick={() => option.onClick && option.onClick(row)}
                                                    >
                                                        <ListItemIcon>{option.icon}</ListItemIcon>
                                                        <ListItemText primary={option.label} />
                                                    </MenuItem>
                                                ),
                                            )
                                        ) : (
                                            <MenuItem>No actions found.</MenuItem>
                                        )}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </Fragment>
    );
};

const renderField = <T extends { id: string | number }>(row: T, column: DatatableColumn<T>, index: any) => {
    if (column.render) return column.render(row);
    else if (column.field) return row[column.field] as string;
    else return 'No render option found.';
};
