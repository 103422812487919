import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getLeadGenQrAvailability, getUtilities, postLeadFromHomeownerForm } from '../../services/leads.service';
import {
    Autocomplete,
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    Skeleton,
    Slide,
    TextField,
    Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { CreateLeadByQrSchema } from 'shared/src/leads/validation/CreateLeadByQrSchema';
import FormikDebugger from '../../components/forms/FormikDebugger';
import { Helmet } from 'react-helmet';
import { FormikVitlSelect, FormikVitlTextField, SubmitButton } from 'react-helpers/src/components/forms';
import FormikGoogleAutocomplete from '../../components/forms/FormikGoogleAutocomplete';
import { BOTTOM_NAV_Z_INDEX } from 'react-helpers';
import { stateCodes } from 'shared/src/constants/stateCodes';
import TopBanner from './TopBanner';

const HomeownerForm = () => {
    const { userId, setterTeamId, storeId } = useParams();
    const { data: isAvailable, isFetched } = useQuery(['leadGenQrAvailability'], () =>
        getLeadGenQrAvailability(userId),
    );

    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    useLayoutEffect(() => {
        if (!userId || !setterTeamId || !storeId) {
            setIsInvalid(true);
        }
    }, [userId, setterTeamId, storeId]);

    const [city, setCity] = useState<string>('');
    const [zip, setZip] = useState<string>('');
    const { data: utilities, isLoading } = useQuery(['utilities', city, zip], () => getUtilities({ city, zip }), {
        enabled: !!zip && !!city,
    });
    const navigate = useNavigate();
    const { mutate: postLeadFromHomeownerFormMutation, isLoading: isPostingLead } = useMutation(
        postLeadFromHomeownerForm,
        {
            onSuccess: (data) => {
                navigate(`/gen/schedule/${data.leadId}/${data.isNewLead}`);
            },
            onError: (error) => {
                console.log('error', error);
            },
        },
    );

    if (!isFetched) return <Box sx={{ textAlign: 'center', p: 2 }}>Loading...</Box>;
    if (!isAvailable) return <Box sx={{ textAlign: 'center', p: 2 }}>Page is not available.</Box>;
    if (isInvalid) return <Box sx={{ textAlign: 'center', p: 2 }}>Invalid URL.</Box>;
    return (
        <Box sx={{ textAlign: 'left', p: 2 }}>
            <Helmet>
                <title>Lowe's Solar Program Homeowner Sign-up</title>
            </Helmet>
            <TopBanner />
            <Formik
                initialValues={{
                    rentOrOwn: undefined,
                    preferredLanguage: undefined,
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    streetAddress1: '',
                    city: '',
                    state: '',
                    zip: '',
                    utilityCompany: '',
                    averageMonthlyUtilityBill: '',
                    approximateCreditScore: '',
                    ecpaDisclosure: false,
                }}
                validationSchema={CreateLeadByQrSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values) => {
                    console.log(values);
                    if (!values.ecpaDisclosure) {
                        alert('Please check the checkbox to continue.');
                        return;
                    }
                    postLeadFromHomeownerFormMutation({
                        ...values,
                        setterId: Number(userId),
                        setterTeamId: Number(setterTeamId),
                        storeId,
                    });
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <FormikDebugger />
                        <Box mb={2}>
                            <FormControl>
                                <FormLabel>Do you rent or own your home?</FormLabel>
                                <RadioGroup
                                    name="rentOrOwn"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('rentOrOwn', event.target.value);
                                    }}
                                >
                                    <FormControlLabel value="Rent" control={<Radio />} label="Rent" />
                                    <FormControlLabel value="Own" control={<Radio />} label="Own" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={2}>
                            <FormControl>
                                <FormLabel>What is your preferred language?</FormLabel>
                                <RadioGroup
                                    name="preferredLanguage"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue('preferredLanguage', event.target.value);
                                    }}
                                >
                                    <FormControlLabel value="English" control={<Radio />} label="English" />
                                    <FormControlLabel value="Spanish" control={<Radio />} label="Spanish" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={2}>
                            <FormikVitlTextField name="firstName" label="First Name" required />
                        </Box>
                        <Box mb={2}>
                            <FormikVitlTextField name="lastName" label="Last Name" required />
                        </Box>
                        <Box mb={2}>
                            <FormikVitlTextField name="email" label="Email" required />
                        </Box>
                        <Box mb={2}>
                            <FormikVitlTextField name="phone" label="Phone" required />
                        </Box>
                        <Box mb={2}>
                            <FormikGoogleAutocomplete
                                sx={{ flex: 1 }}
                                onPlaceSelected={(places) => {
                                    const streetNumber = places.address_components.find((a) =>
                                        a.types.includes('street_number'),
                                    );
                                    const route = places.address_components.find((a) => a.types.includes('route'));
                                    const locality = places.address_components.find((a) =>
                                        a.types.includes('locality'),
                                    );
                                    const administrative_area_level_1 = places.address_components.find((a) =>
                                        a.types.includes('administrative_area_level_1'),
                                    );
                                    console.log(places);
                                    const zip = places.address_components.find((a) => a.types.includes('postal_code'));
                                    setFieldValue('streetAddress1', streetNumber?.long_name + ' ' + route?.long_name);
                                    setFieldValue('city', locality?.long_name);
                                    setCity(locality?.long_name || '');
                                    setFieldValue(
                                        'state',
                                        administrative_area_level_1?.short_name ||
                                            stateCodes.find((x) => x.name === administrative_area_level_1.long_name)
                                                ?.abbreviation,
                                    );
                                    setFieldValue('zip', zip?.long_name);
                                    setZip(zip?.long_name || '');
                                    if (!streetNumber || !route || !locality || !administrative_area_level_1 || !zip) {
                                        alert(
                                            'Sorry, Google malformed the address. Please enter the address manually.',
                                        );
                                    }
                                }}
                                name="streetAddress1"
                                label="Address"
                                required
                            />
                        </Box>
                        <Box mb={2}>
                            <FormikVitlTextField name="city" label="City" required />
                        </Box>
                        <Box mb={2}>
                            <Autocomplete
                                slotProps={{
                                    popper: {
                                        sx: {
                                            zIndex: BOTTOM_NAV_Z_INDEX + 2,
                                        },
                                    },
                                }}
                                options={['', ...stateCodes.map((state) => state.abbreviation)]}
                                getOptionLabel={(option) => option}
                                value={values.state}
                                onChange={(e, value) => {
                                    setFieldValue('state', value);
                                }}
                                renderInput={(params) => (
                                    <FormikVitlTextField {...params} name="state" label="State" required />
                                )}
                            />
                        </Box>
                        <Box mb={2}>
                            <FormikVitlTextField name="zip" label="Zip" required />
                        </Box>
                        {!!city && !!zip && (
                            <Slide in={!!city && !!zip} direction="left">
                                <Box mb={2}>
                                    {isLoading ? (
                                        <Skeleton width={'100%'}>
                                            <TextField fullWidth />
                                        </Skeleton>
                                    ) : (
                                        <Autocomplete
                                            slotProps={{
                                                popper: {
                                                    sx: {
                                                        zIndex: BOTTOM_NAV_Z_INDEX + 2,
                                                    },
                                                },
                                            }}
                                            options={utilities?.data.map((u) => u.label)}
                                            getOptionLabel={(option) => option}
                                            value={values.utilityCompany}
                                            onChange={(e, value) => {
                                                setFieldValue('utilityCompany', value);
                                            }}
                                            renderInput={(params) => (
                                                <FormikVitlTextField
                                                    {...params}
                                                    name="utilityCompany"
                                                    label="Utility Company"
                                                    required
                                                />
                                            )}
                                        />
                                    )}
                                </Box>
                            </Slide>
                        )}
                        <Box mb={2}>
                            <FormikVitlSelect
                                name="averageMonthlyUtilityBill"
                                label="Average Monthly Utility Bill"
                                required
                                options={[
                                    { label: '$50 - $99', value: '$50 - $99' },
                                    { label: '$100 - $200', value: '$100 - $200' },
                                    { label: '$201 - $300', value: '$201 - $300' },
                                    { label: '$301 - $400+', value: '$301 - $400+' },
                                ]}
                            />
                        </Box>
                        <Box mb={2}>
                            <FormikVitlSelect
                                name="approximateCreditScore"
                                label="Approximate Credit Score (optional)"
                                options={[
                                    { label: 'Above 640', value: 'Above 640' },
                                    { label: 'Below 640', value: 'Below 640' },
                                ]}
                            />
                        </Box>
                        <Divider />
                        <Box my={2}>
                            <Typography sx={{ mb: 2 }} variant="h6">
                                Contacting you
                            </Typography>
                            <FormControl
                                component="fieldset"
                                onChange={(e: any) => {
                                    setFieldValue('ecpaDisclosure', e.target.checked);
                                }}
                            >
                                <FormGroup row>
                                    <FormControlLabel
                                        value="bottom"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                        }}
                                        control={<Checkbox size="large" />}
                                        label={
                                            <>
                                                By clicking the checkbox, I agree by electronic signature to: (1)
                                                receive recurring automated marketing and other calls, texts, and
                                                prerecorded messages from VITL, LLC at the number I entered above, even
                                                if I am on a "Do Not Call" list (consent not required to make a
                                                purchase, msg & data rates apply, reply STOP to opt-out of texts or HELP
                                                for help); (2) the{' '}
                                                <a
                                                    href="https://disclosures.vitlapps.com/"
                                                    target="_blank"
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                >
                                                    Terms of Use
                                                </a>{' '}
                                                (including arbitration provision); and (3) the{' '}
                                                <a
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                    href="https://disclosures.vitlapps.com/vitl-power-privacy-policy"
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </a>{' '}
                                                and{' '}
                                                <a
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                    href="https://disclosures.vitlapps.com/vitl-power-privacy-notice-for-california-residents"
                                                    target="_blank"
                                                >
                                                    California Privacy Notice
                                                </a>
                                                .
                                            </>
                                        }
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                        <Box mb={8}>
                            <SubmitButton isLoading={isPostingLead}>Continue</SubmitButton>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default HomeownerForm;
