import { DesktopDatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import moment, { Moment } from 'moment';
import { TextFieldProps } from '@mui/material';

type FormikVitlDateFieldProps = {
    name: string;
    label: string;
    textFieldProps: TextFieldProps;
} & DatePickerProps<Moment>;

/**
 * value must be a string in YYYY-MM-DD format
 */
const FormikVitlDateField = ({ name, label, textFieldProps, ...rest }: FormikVitlDateFieldProps) => {
    const [field, meta, helpers] = useField(name);
    const { setFieldValue } = useFormikContext();

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                {...field}
                label={label}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        sx: {
                            zIndex: 4004,
                        },
                        ...textFieldProps,
                    },
                    popper: {
                        sx: {
                            zIndex: 4004,
                        },
                    },
                }}
                closeOnSelect={true}
                name={field.name}
                value={field.value ? moment.utc(field.value) : null}
                onChange={(mDate: Moment | null) => {
                    if (!mDate || !mDate.isValid()) {
                        setFieldValue(name, null);
                    }
                    setFieldValue(name, mDate.format('YYYY-MM-DD'));
                }}
                {...rest}
            />
        </LocalizationProvider>
    );
};

export default FormikVitlDateField;
