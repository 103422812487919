import { Appointment, Lead, PrismaTypes, User } from 'database';
import { GetDtosQueryParams } from '../base';
import * as LeadValidation from './validation';
import * as SetterReportFactory from './setterReportFactory';
import moment, { Moment } from 'moment';

export class GetLeadsQueryParams extends GetDtosQueryParams {
    startSitDate?: Date;
    endSitDate?: Date;
    dispositionIds?: number[];
    setterTeamIds?: number[];
    closerTeamIds?: number[];
    setterIds?: number[];
    closerIds?: number[];
    startSetDate?: Date;
    endSetDate?: Date;
    callCenterDispositionIds?: number[];
}

export class GetAppointmentsQueryParams extends GetDtosQueryParams {
    startSitDate?: Date;
    endSitDate?: Date;
    dispositionIds?: number[];
    setterTeamIds?: number[];
    closerTeamIds?: number[];
    setterIds?: number[];
    closerIds?: number[];
}

export type Disposition = (typeof Dispositions)[keyof typeof Dispositions];

export const Dispositions = {
    Closed: 'Closed',
    DidNotClose: 'Did Not Close',
    Rescheduled: 'Rescheduled',
    Canceled: 'Canceled',
    DNQ: 'DNQ',
} as const;

export interface DispositionDto {
    closerTeamId: string;
    dispositionId: number;
    appointmentDate?: Date;
    startTime?: string;
    endTime?: string;
    subdispositionId?: number | null;
    dispositionReason?: string;
    projectId?: string;
}

export interface ICreateAppointment {
    startTime: string;
    endTime: string;
    appointmentDate: Date;
    closerTeamId: string;
}

export interface IUpdateAppointment extends ICreateAppointment {}

export interface RescheduleAppointmentDto extends ICreateAppointment {
    rescheduleReason: string;
}

export type AvailableCloser = User & {
    hasConflict: boolean;
    conflictName?: string;
    conflictStartTime?: string;
    conflictEndTime?: string;
};

export { LeadValidation };
export { SetterReportFactory };

export function getCurrentAppointment(lead: Lead) {
    if (!lead.appointments || lead.appointments.length === 0) return null;
    return lead.appointments.find((a) => a.isCurrent);
}

export const NO_DISPOSITION_ID_DTO = 0;

export type ExpandedAppointment = Appointment & { firstAppointmentOnLead?: Appointment };
export type AppointmentExport = {
    ['ID']: string;
    ['Lead Name']: string;
    ['Address 1']: string;
    ['Address 2']: string;
    ['City']: string;
    ['State']: string;
    ['Zip']: string;
    ['Phone']: string;
    ['Setter']: string;
    ['Setter Team']: string;
    ['Closer']: string;
    ['Closer Team']: string;
    ['Sit Date']: string;
    ['Start Time']: string;
    ['End Time']: string;
    ['Lead Created Date']: string;
    ['Appointment Created Date']: string;
    ['Disposition']: string;
    ['Subdisposition']: string;
    ['Disposition Reason']: string;
    ['Notes']: string;
    ['Is Net Possible Sit']: string;
    ['Store']: string;
};

export type LeadExport = {
    ['ID']: string;
    ['Lead Name']: string;
    ['Lead Created Date']: string;
    ['Address 1']: string;
    ['Address 2']: string;
    ['City']: string;
    ['State']: string;
    ['Zip']: string;
    ['Phone']: string;
    ['Setter']: string;
    ['Setter Team']: string;
    ['Store']: string;
    ['Notes']: string;
    ['Current Appointment Closer']: string;
    ['Current Appointment Closer Team']: string;
    ['Current Appointment Date']: string;
    ['Current Appointment Disposition']: string;
    ['Current Appointment Subdisposition']: string;
    ['Current Appointment Disposition Reason']: string;
};

export interface PostLeadAttachmentRequest {
    filePath: string;
    description: string;
    tag: string;
    uploadedById: number;
    leadId: string;
}

export interface PublicLead {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: string;
    streetAddress1: string;
    city: string;
    state: string;
    zip: string;
    setterTeamId: number;
    appointments: {
        id: string;
        sitDate: Date;
        startTime: string;
        endTime: string;
        dispositionId: number;
        isCurrent: boolean;
    }[];
}

export interface ScheduleAppointmentFromHomeownerFormDto {
    appointmentDate: Date;
    startTime: string;
    endTime: string;
    setterTeamId: number;
}

export interface PublicAppointment {
    id: string;
    sitDate: Date;
    startTime: string;
    endTime: string;
    lead: {
        id: string;
        firstName: string;
        lastName: string;
        fullName: string;
        email: string;
        phone: string;
        streetAddress1: string;
        city: string;
        state: string;
        zip: string;
        setterTeamId: number;
    };
}

export interface CreateAppointmentFromHomeownerFormDto {
    startTime: string;
    appointmentDate: string;
}

export interface TaskReminderDto {
    body: string;
    toSendAt: string;
    // user ids
    subscribers: number[];
}

export const convertAppointmentDateDataToUtc = (
    momentDate: Moment,
    startTime: string,
    endTime: string,
    offsetInSeconds: number,
) => {
    if (momentDate.hours() !== 0 || momentDate.hours() !== 0) {
        throw new Error('Date must be at midnight UTC.');
    }
    const startNumHours = Number(startTime.split(':')[0]);
    const endNumHours = Number(endTime.split(':')[0]);
    const startNumMinutes = Number(startTime.split(':')[1]);
    const endNumMinutes = Number(endTime.split(':')[1]);
    const startDateTimeMoment = momentDate
        .clone()
        .add(startNumHours, 'hours')
        .add(startNumMinutes, 'minutes')
        .subtract(offsetInSeconds, 'seconds');
    const endDateTimeMoment = momentDate
        .clone()
        .add(endNumHours, 'hours')
        .add(endNumMinutes, 'minutes')
        .subtract(offsetInSeconds, 'seconds');

    const startDateMinus30Minutes = startDateTimeMoment.clone().subtract(30, 'minutes');
    const endDatePlus30Minutes = endDateTimeMoment.clone().add(30, 'minutes');
    return {
        startDateUtc: startDateTimeMoment.toDate(),
        endDateUtc: endDateTimeMoment.toDate(),
        checkInAvailableStartDateTimeUtc: startDateMinus30Minutes.toDate(),
        checkInAvailableEndDateTimeUtc: endDatePlus30Minutes.toDate(),
    };
};

export interface CheckInDto {
    appointmentId: string;
    checkInLat: number;
    checkInLng: number;
}

export const CheckInStatuses = {
    OnSiteOnTime: 'On Site, On Time',
    OffSiteOnTime: 'Off Site, On Time',
    OnSiteLate: 'On Site, Late',
    OffSiteLate: 'Off Site, Late',
} as const;

export type CheckInStatus = (typeof CheckInStatuses)[keyof typeof CheckInStatuses];
