import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import useConfirmDialog from './useConfirmDialog';

const ConfirmDialog = () => {
    const { isOpen, setIsOpen, body, onConfirm, onCancel, confirmText, cancelText } = useConfirmDialog();

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
        setIsOpen(false);
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
        setIsOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog open={isOpen} onClose={handleCancel}>
                <DialogContent sx={{ textAlign: 'center' }}>{body}</DialogContent>
                <DialogActions sx={{ display: 'flex' }}>
                    <Button onClick={handleCancel} variant="contained" color="error" sx={{ flex: 1 }}>
                        {cancelText}
                    </Button>
                    <Button onClick={handleConfirm} autoFocus variant="contained" color="success" sx={{ flex: 1 }}>
                        {confirmText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ConfirmDialog;
