// import moment from 'moment-timezone';

export const StringEnum = <T extends string>(o: Array<T>): { [K in T]: K } => {
    return o.reduce((res, key) => {
        res[key] = key;
        return res;
    }, Object.create(null));
};

export const asCurrency = (num: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(num);
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const time24to12 = (time24?: string, config: { withMeridian: boolean } = { withMeridian: true }) => {
    if (!time24) return '';
    let ts = time24;
    let H = Number(ts.substr(0, 2));
    let h = H % 12 || 12;
    let stringH = h < 10 ? '0' + h.toString() : h.toString(); // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? ' AM' : ' PM';
    ts = stringH + ts.substr(2, 3);
    if (config.withMeridian) ts += ampm;
    return ts[0] === '0' ? ts.substring(1) : ts;
};

export const getHoursFrom24 = (time24?: string, config: { withMeridian: boolean } = { withMeridian: true }) => {
    if (!time24) return 0;
    let ts = time24;
    let H = Number(ts.substr(0, 2));
    let h = H % 12 || 12;
    return h;
};

export const getMinutesFrom24 = (time24?: string, config: { withMeridian: boolean } = { withMeridian: true }) => {
    if (!time24) return 0;
    return time24.substr(3, 2);
};

export const reduceToSum = (accumulator: number, current: number) => accumulator + current;

export const downloadFile = ({ data, fileName, fileType }: { data: any; fileName: any; fileType: any }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const toHumanReadblePhone = (phone: string) => {
    if (phone.length === 10) return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    if (phone.length === 11) return `(${phone.substr(1, 3)}) ${phone.substr(4, 3)}-${phone.substr(7, 4)}`;
    else return phone;
};

export class Enumerable<T extends string> {
    constructor(arr: Array<T>) {
        this.enum = StringEnum(arr);
        this.array = arr;
    }

    array: Array<T>;
    enum: { [K in T]: K };

    getSelectOptions() {
        return [{ text: '', value: '' }, ...this.array.map((a) => ({ text: a, value: a }))];
    }
}

export const makeid = ({ length }: { length: number }) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

// export function time24ToUtcTimestamp(inputTime: string, timezone: string, asUtc?: boolean): string {
//     const inputMoment = moment(inputTime, 'HH:mm');

//     if (asUtc) {
//         inputMoment.utc();
//     } else {
//         inputMoment.tz(timezone);
//     }

//     return inputMoment.format('HH:mm:ss');
// }

// export function utcTimestampToTime12(utcTimestamp: string, timezone: string): string {
//     const utcMoment = moment.utc(utcTimestamp, 'HH:mm:ss');
//     const timezoneMoment = utcMoment.tz(timezone);
//     const formattedTime = timezoneMoment.format('hh:mm A');

//     return formattedTime;
// }
